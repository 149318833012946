import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import React, {lazy, Suspense } from "react"
import {Route, Routes} from "react-router-dom"
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from './components/Loading';
import PrivateRoute from './components/PrivateRoute';
import PageNotFound from './pages/PageNotFound';
const Auth = lazy(()=> import('./pages/Auth'))
const CreateInvoice = lazy(()=> import('./pages/CreateInvoice'))
const Dashboard = lazy(()=> import('./pages/Dashboard'))
const Profile = lazy(()=> import('./pages/Profile'))
const Product = lazy(()=> import('./pages/Product'))
const CreateProduct = lazy(()=> import('./pages/CreateProduct'))
const EditProduct = lazy(()=> import('./pages/EditProduct'))
const DashboardStorage = lazy(()=> import('./pages/DashboardStorage'))
const CreateInvoiceStoarge = lazy(()=> import('./pages/CreateInvoiceStorage'))
const EditInvoiceStorage = lazy(()=> import('./pages/EditInvoiceStorage'))
const ProductStorage = lazy(()=> import('./pages/ProductStorage'))
const CreateProductStorage = lazy(()=> import('./pages/CreateProductStorage'))
const EditProductStorage = lazy(()=> import('./pages/EditProductStorage'))
const Sell = lazy(()=> import('./pages/Sell'))
const Archive = lazy(()=> import('./pages/Archive'))
const Customer = lazy(()=> import('./pages/Customer'))
const CreateCustomer = lazy(()=> import('./pages/CreateCustomer'))
const EditCustomer = lazy(()=> import('./pages/EditCustomer'))

const theme = createTheme({
  typography: {
    fontFamily: [
      'Sora',
      'sans-serif',
    ].join(','),
  },});

function App() {
  return (
    <div>
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Suspense fallback={<Loading/>}>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboardstorage" element={<DashboardStorage />} />
          <Route path="/newinvoice" element={<CreateInvoice />} />
          <Route path="/newinvoicestorage" element={<CreateInvoiceStoarge />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/product" element={<Product />} />
          <Route path="/productstorage" element={<ProductStorage />} />
          <Route path="/newproduct" element={<CreateProduct />} />
          <Route path="/newproductstorage" element={<CreateProductStorage />} />
          <Route path="/editproduct" element={<EditProduct />} />
          <Route path="/editproductstorage" element={<EditProductStorage />} />
          <Route path="/editinvoicestorage" element={<EditInvoiceStorage />} />
          <Route path="/sell" element={<Sell />} />
          <Route path="/archive" element={<Archive />} />
          <Route path="/newcustomer" element={<CreateCustomer />} />
          <Route path="/editcustomer" element={<EditCustomer />} />
          <Route path="/customer" element={<Customer />} />
        </Route>
        {/* <Route path="/login" element={<Auth />} /> */}
        <Route path="*" element={<Auth />} />
        
      </Routes>
      </Suspense>
      
        <ToastContainer />
       
      </ThemeProvider>
    </div>
  );
}

export default App;
