import { createSlice} from '@reduxjs/toolkit'

export const invoiceListSlice = createSlice({
  name: 'invoicelist',
  initialState : {
    value: []
  },
  reducers: {
    setInvoiceList: (state, action) => {
      state.value = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setInvoiceList} = invoiceListSlice.actions

export default invoiceListSlice.reducer