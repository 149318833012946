import { createSlice} from '@reduxjs/toolkit'

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState : {
    value: {
      customername: "",
      customerphone: "",
      total: "",
      advance: "",
      balance: "",
      invoiceno: "",
      invoicedate:"",
      itemList: [],
      sellList: [],
      customList: []
    }
  },
  reducers: {
    setInvoice: (state, action) => {
      state.value = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setInvoice} = invoiceSlice.actions

export default invoiceSlice.reducer