import {configureStore} from  "@reduxjs/toolkit"
import userReducer from "./user"
import invoiceReducer from "./invoice"
import invoiceListReducer from "./invoicelist"

export const store = configureStore({
    reducer: {
      user: userReducer,
      invoice: invoiceReducer  ,
      invoicelist: invoiceListReducer
    }
})