import { createSlice} from '@reduxjs/toolkit'

export const userSlice = createSlice({
  id: '',
  ownerphone: '',
  name:'user',
  business: '',
  logo: '',
  phone: '',
  address: '',
  lastinvoiceno: '',
  watermark: '',
  facebook: '',
  initialState : {
    user: {
      
     }
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUser} = userSlice.actions

export default userSlice.reducer